/* You can add global styles to this file, and also import other style files */
@import '~angular-calendar/css/angular-calendar.css';
@import '~ngx-toastr/toastr.css';
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import'app/font-icon/material-icons.css';
/* @import './app/master/new-designation/new-designation.component.css'; */
/* @import "../node_modules/bootstrap/dist/css/bootstrap.min.css"; */

body {
  font-family: Roboto, Arial, sans-serif;
  overflow-x: hidden;
  margin: 0;

}

.basic-container {
  /*padding: 5px;*/
  padding: 0px;
}

.version-info {
  font-size: 8pt;
  float: right;
}
/*Header*/
.head-bg {
  /*background: #292c5f;*/
  /*background: #3268ab;*/
  padding: 7px 0;
  box-shadow: 0px 6px 2px -3px rgba(0,0,0,0.75);
  /*header Gradient Color*/
  background: #053960; /* Old browsers */
  background: -moz-linear-gradient(top, #053960 0%, #195695 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #053960 0%,#195695 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #053960 0%,#195695 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#053960', endColorstr='#195695',GradientType=0 ); /* IE6-9 */
}

.logo {
  width: 50%;
  margin-top: 14px;
  padding: 0 16px;
  float: left;
}

  .logo img {
    float: left;
    margin-right: 17px;
  }

  .logo h1 {
    color: #f1914b;
    /*color: #f9c448;*/
    font-size: 170%;
    font-family: initial;
    font-weight: 700;
    margin: auto;
    text-transform: uppercase;
    margin-top: 5px;
  }

  .logo span {
    color: #fff;
    font-size: 132%;
    margin-top: 6px;
    float: left;
  }

.emblem img {
  max-width: 50px;
  float: left;
}


/*left nav*/
/* .mat-sidenav {  background: #021c4d;} */

.mat-sidenav {
  width: 300px;
  /*background-image: url('./assets/bgg.jpg');
  border: none;*/
}


  .mat-sidenav:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /*background-color: #3d3f6f;*/
    /*background-color: #3268ab;*/
    background: #e8e8e8;
    opacity: 0.9;
  }


.mat-sidenav-container {
  height: 100vh;
}

.sidenav-toolbar {
  height: 64px;
  display: flex;
  flex-direction: row;
}

.sidebar {
  width: 260px;
  position: fixed;
}

.main-panel {
  position: relative;
  float: right;
  width: calc(100% - 260px);
  -webkit-transition: all .5s cubic-bezier(.685,.0473,.346,1);
  transition: all .5s cubic-bezier(.685,.0473,.346,1);
}

.app-nav-menu > li {
  float: left;
}

.mat-list-base .mat-list-item {
  color: #125083;
}

.mat-list-item-content .mat-icon {
  margin-right: 4px;
  font-size: 18px;
  margin-top: 7px;
  color: #db6e11;
}

.mat-drawer-container, .mat-drawer {
  padding-top: 0px !important;
}






/*Main Page(Dashboard)*/
.main-background {
  background-image: url('./assets/main-bg.jpg');
  height: 100vh;
  background-size: cover;
}


/*Cards 28-11-18*/

  .card .card-icon {
    border-radius: 3px;
    background-color: #999;
    padding: 10px; /*25px;*/
    /*margin-top: -20px;*/
    margin-right: 15px;
    float: left;
    box-shadow: 0 4px 20px 0 rgba(0,0,0,.14), 0 7px 10px -5px rgba(255,152,0,.4);
  }

  .card .card-header-warning .card-icon {
    background: linear-gradient(60deg,#ffa726,#fb8c00);
  }

  .card .card-header-success .card-icon {
    background: linear-gradient(60deg,#66bb6a,#43a047);
  }

  .card .card-header-danger .card-icon {
    background: linear-gradient(60deg,#ef5350,#e53935);
  }

  .card .card-header-info .card-icon {
    background: linear-gradient(60deg,#26c6da,#00acc1);
  }

  .card .card-icon i {
    color: #fff
  }

.card-icon i {
  font-size: 36px;
}

.card p.card-category {
  text-align: right;
  font-size: 14px;
  color: #999;
  padding-top: 14px;
}

.card h3 {
  text-align: right;
  color: #999;
  margin: 0;
}

.card-footer {
  border-top: 1px solid #eee;
  margin-top: 20px;
}

  .card-footer .stats {
    color: #999;
    font-size: 12px;
  }

.card .card-footer .stats .material-icons {
  position: relative;
  top: 4px;
  font-size: 16px;
  padding-top: 10px;
}

/*Chart*/
.graph-body {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,.14);
  padding: 10px;
}

.mt-40 {
  margin-top: 40px;
}

.mat-drawer-content {
  /*background-image: url('./assets/main-bg.jpg');
  height: 100vh;
  background-size: cover;*/
  background: #f7f7f7;
  /*height:auto !important;*/
}

/*.breadcrumb {
  background-color: #dadada !important;
  border-radius: 0 !important;
  border-left: 7px solid #323232;
}*/

/*15/jan/18*/
/*.mat-step-header {
  width: 220px;
  float: left;
  border-right: 1px solid #e4e4e4;
}

  .mat-step-header:hover {
    background-color: #cfcdd8;
  }

.mat-step.ng-tns-c11-2.ng-star-inserted {
  width: 100%;
  float: left;
}

.mat-vertical-content-container {
  width: calc(100% - 270px);
  margin-left: 0 !important;
  top: 93px;
  position: absolute !important;
  left: 270px;
}

.detail_one {
  width: 55%;
  float: left;
  max-width: 100% !important;
}

.detail_two {
  width: 42%;
  float: left;
  margin: 0 !important;
}*/

.w-100 {
  width: 100%;
}

.mat-stepper-vertical-line::before {
  border-left-style: none !important;
}

.mr-25 {
  margin-right: 25px !important;
}
/*31/jan/19*/
.btn-bg {
  background: #e7e8ef;
  border: 1px solid #e4dcdc;
}

.mat-vertical-stepper-header {
  padding: 17px 25px !important;
}

/*04-2-19*/
.wid-100 {
  width: 100% !important;
}

.mb-15 {
  margin-bottom: 15px;
}

.fom-title {
  color: #fff;
  margin-bottom: 15px;
  font-weight: 700;
  border-bottom: 1px solid #adadad;
  padding-bottom: 10px;
  /*background: #d5ebe8;*/
  background: #2468b3;
  padding: 9px 0 5px 13px;
  clear: both;
  width: 100%;
  /*margin-top: 15px;*/
  float: left;
}

.combo-col {
  padding-top: 20px;
  padding-bottom: 6px;
}

.mat-card {
  box-shadow: none;
  border: 1px solid #cccccc !important;
  width: 100%;
  float: left;
  /*background: #f0f7f6;*/
  background: #fff;
  padding: 16px;
  margin-top:5px;
}

.select-lbl {
  margin-top: 18px;
}

.selection-hed {
  /*background-color: #e4e4e4;*/
  background-color: #d6d6d6;
  border-left: 6px solid #929292;
  margin-bottom: 10px;
  padding: 8px 0 6px 0;
  border-top: 1px solid #bdb4b4;
  border-right: 1px solid #bdb4b4;
  border-bottom: 1px solid #bdb4b4;
}

.go-btn {
  box-shadow: none !important;
  margin-top: 10px !important;
}

.pading-0 {
  padding: 0 !important;
}

mat-radio-group > mat-radio-button:nth-child(1) {
  margin-right: 15px;
}

.radio_btn_gender mat-radio-group > mat-radio-button:nth-child(2) {
  margin-right: 15px;
}

.mat-radio-label-content {
  font-weight: normal;
}

.dialog__close-btn {
  top: 8px !important;
  right: 12px !important;
}

.i-info {
  margin-right: 6px;
  color: #696969;
  font-size: 21px;
}

.i-edit {
  margin-right: 6px;
  color: #20a32a;
  font-size: 21px;
  cursor: pointer;
}

.i-delet {
  color: #ef4b4b;
  font-size: 23px;
  cursor: pointer;
}

.i-activate {
  color: #12ab33;
  font-size: 21px;
  cursor: pointer;
  margin-right: 6px;
  width: 21px;
}

.i-dactivate {
  color: #ea1b1b;
  font-size: 21px;
  cursor: pointer;
  margin-right: 6px;
  width: 21px;
}

.i-activate:focus, .i-activate:hover {
  text-decoration: none;
}


.mat-header-cell {
  font-size: 15px;
  font-weight: 600;
  color: #0a0a0a;
}

.btn {
  margin: 0 10px;
  min-width: 64px;
}

.btn-wraper {
  margin-top: 25px;
}
/*22-2-19*/
.gender-mr {
  margin-right: 15px;
}

.popupbox .mat-form-field-infix {
  width: 100% !important;
}

/*.even-odd-color tr:nth-child(even) {
  background: #e9f4fb;
}*/

.border-non {
  border: none !important;
}

/*Check Box*/
.checkbox .mat-list-text {
  color: #000 !important;
}

.checkbox .mat-list-option {
  display: inline-block !important;
  width: auto !important;
}

.checkbox .mat-list-item-content-reverse {
  padding: 0 5px !important;
}
/*14-3-19*/
.declaration-fom {
  width: 100%;
  float: left;
}

  .declaration-fom > ul > li {
    display: flex;
    margin-bottom: 12px;
  }

ol.lower-roman > li {
  list-style-type: lower-roman;
  margin-bottom: 15px;
}

ol.lower-roman {
  margin-top: 10px;
}

.dispp-block {
  display: block !important;
}

.declaration-fom > ul > li > mat-checkbox {
  margin-right: 10px;
}

.declaration-fom span {
  font-size: 17px;
}

.declaration-fom {
  background: #f1fbf0;
  border-style: dotted;
  border-color: #d8ded7;
}

.combo-col-2 {
  padding-top: 20px;
  padding-bottom: 13px;
}

.combo-col-3 {
  padding-top: 20px;
  padding-bottom: 0px;
}
/*upload file*/
.upload-file-label {
  width: auto;
  float: left;
  margin-right: 5px;
}

.check-list-opt {
  height: 7px !important;
  clear: both !important;
}

.float-left {
  float: left;
}
/*28-03-2019*/
.select-type {
  background: #e8f7de;
  border: 1px #b3c5a6;
  border-style: dashed;
  padding: 7px 10px 0px 11px;
  margin-bottom: 15px;
}

.icon-right {
  position: absolute;
  right: 0;
}

/*02-04-2019*/
.even-odd-color {
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
}

.mat-table {
  width: 100%;
}

.mat-step.ng-star-inserted {
  width: 100%;
  float: left;
}

.tabel-wraper { /*max-height: 550px;width: 550px;*/
  overflow: auto;
  max-height: 735px;
}

  .tabel-wraper table {
    min-width: 500px;
  }

  .tabel-wraper tr > td {
    /*min-width: 105px;*/
  }

  /* width */
  .tabel-wraper::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  /* Track */
  .tabel-wraper::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  .tabel-wraper::-webkit-scrollbar-thumb {
    background: #a8a8a8;
  }

    /* Handle on hover */
    .tabel-wraper::-webkit-scrollbar-thumb:hover {
      background: rgba(0,0,0,.2);
    }

.emp2-head {
  width: 100%;
  float: left;
}
/**/
.peragraph-input {
  width: auto;
  float: left;
  padding: 16px 4px 10px 0px;
}

@media only screen and (max-width: 1627px) and (min-width: 1580px) {
  .peragraph-input {
    padding: 16px 9px 0 9px;
  }
}

@media only screen and (max-width: 1755px) and (min-width: 1628px) {
  .peragraph-input {
    padding: 16px 0 13px 0;
  }
}

/*checkbox suspensiondetail*/
.order-checkbox {
  float: left;
  padding: 0;
  margin: 0px 0 6px 0 !important;
}

.attach-dattach-wraper {
  margin: 25px 0;
}

.attach-btn {
  background: #3c5b76;
  color: #fff;
  margin-right: 17px !important;
}

.dattach-btn {
  background: #4cb39b;
  color: #fff;
}

.pading-16 {
  padding-top: 16px;
}

.display-non {
  display: none;
}

.breadcrumb > li > a {
  color: #5d5656;
  /* font-weight: 600; */
}
/*Manoj pal 15-5-19*/
.filter-option > .mat-pseudo-checkbox {
  display: none !important;
}
/*Employee1*/
.emp1-form {
  max-height: 790px;
  height: 790px;
  /*background: #f0f7f6;*/
}

.emp1-table {
  max-height: 790px;
  height: 790px;
}

.delete-leave-btn {
  padding: 2px 0 0 0 !important;
  margin: auto;
  min-width: 34px !important;
  margin-bottom: 12px !important;
}
/*07-2-19*/
.material-icons {
  cursor: pointer;
}
/*13-3-19*/
.leave-wraper {
  width: 100%;
  float: left;
  background: #f9f9f9;
  border: 1px solid #d9dcdb;
  margin: 10px 0px 25px 0;
  padding: 16px 0;
}

.add-leave-icon {
  float: left;
  margin-right: 11px;
}

.add-leave-btn {
  padding: 2px 0 0 0 !important;
  margin: auto;
  min-width: 34px !important;
  margin-bottom: 12px !important;
  margin-right: 10px;
  /*line-height: 23px !important;
  margin-bottom: 15px !important;
  margin-right: 0;
  border: none !important;
  background-color: #504b71 !important;
  color: #fff !important;*/
}

  .add-leave-btn:hover {
    /*background: #746da0 !important;*/
  }

.text-right {
  text-align: right;
}

.line-height-57 {
  line-height: 57px;
}
/*Nav Active Hover*/
.menu-list-item.mat-list-item.active {
  background: #f7f7f7 !important;
  color: #000;
  font-weight: bold;
  z-index: 1111;
  border-top: 1px solid #c1c1c1;
  border-bottom: 1px solid #c1c1c1;
}

.select-drop-head {
  margin: 0 30px;
}

.select-lbl {
  float: left;
  font-size: 17px;
  color: #404040;
  font-weight: bold !important;
}

.require-field-position {
  top: 0;
  position: absolute;
  right: 71px;
  font-size: 11px;
}

.commutation-table {
  padding: 5px;
  border: 1px solid #889492;
}

  .commutation-table > tbody > tr {
    line-height: 31px;
  }

    .commutation-table > tbody > tr > td {
      padding-left: 5px;
    }

      .commutation-table > tbody > tr > td > th {
        padding-left: 5px;
      }

.btn-search {
  background: #28a745;
  border: none;
  color: white;
  padding: 6px 12px 6px 12px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 6px;
  margin-left: 18px;
}

  .btn-search:hover {
    background-color: #10902d;
  }

@media only screen and (max-width: 1727px) and (min-width: 1200px) {

  .btn-search {
    padding: 3px 8px 3px 8px;
    font-size: 15px;
    margin-top: 6px;
    margin-left: 0;
  }
}
/*17-06-19*/
.add-del-btn {
  padding: 0 !important;
  margin-top: 10px;
}

.tabel-form > tbody > .table-head {
  /*line-height: 17px;*/
  background: #4671a9;
  color: #fff;
}

.tabel-form > tbody > tr > th {
  padding-left: 9px;
  padding: 4px !important;
}

.tabel-form > tbody > tr > td {
  /*line-height: 35px;*/
  /*padding-left: 9px;
  padding-right: 5px;*/
}

.tabel-form > tbody > tr:nth-child(even) {
  background: #fff;
}

/**/
label {
  font-weight: normal !important;
  font-size: inherit;
  color: #7d7d7d;
}
/*20-06-2019*/
.mat-list-base .mat-list-item {
  font-size: 15px;
  border: 1px solid #c3c3c3;
}

.action-single-btn {
  text-align: center;
  line-height: normal !important;
  margin: 0;
  padding: 0;
}
/*25-06-2019*/
span.btn-label {
  position: relative;
  left: -12px; /* display: inline-block; */
  padding: 8px 12px;
  background: rgba(0,0,0,0.15);
  border-radius: 3px 0 0 3px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.margin-0 {
  margin: 0px;
}

h4.card-title.text-center {
  margin-bottom: 40px;
}

.margin-top-10px {
  margin-top: 10px;
}

.tabel-total {
  font-weight: 600;
  font-size: 15px;
}

/*.recovery-tabel > tbody > tr > td:last-child {
  text-align: center;
}*/
.upload-container {
  margin-bottom: 19px;
  margin-top: 10px;
  padding-left: 0 !important;
}


/*SHOWING LOADING*/
.LoadingBar {
  position: fixed;
  background-color: rgba(0,0,0,0.5);
  z-index: 2147483647 !important;
  opacity: 0.8;
  overflow: hidden;
  text-align: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; /*padding-top: 20%;*/
  /*background-image: url('../../Images/Loader.gif');*/
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10000000;
  filter: alpha(opacity=40);
}

.LoadBusy {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%; /*background-color:#666;*/
  /*background-image: url('../../Images/Loader.gif');*/
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10000000;
  opacity: 0.4;
  filter: alpha(opacity=40); /* For IE8 and earlier */
}
/*END OF SHOWING LOADING*/

/*28-05-2019*/
.view-btn {
  background: #4299ed;
  color: #fff;
  padding: 6px 15px 6px 15px;
  height: auto;
  margin: 0;
  line-height: normal;
  border-radius: 4px;
  border: none;
}
/*01-07-19*/

.tbl-input > tbody > tr > td > input {
  width: 100%;
}

.add-list {
  background: #2ec48d !important;
  border: none !important;
}

.remove-list {
  background: #f35b21 !important;
  border: none !important;
}

.add-list:hover {
  background: #28aa7b !important;
}

.remove-list:hover {
  background: #d44e1b !important;
}

.float-r {
  float: right;
}

.dialog-wraper {
}

/*09-07-2019
.login-left {height: 100vh;  background: #3268ab;}
.login-emblem {
  background-image: url('./assets/emblem-login.png');
  background-repeat: no-repeat;
  height: 294px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width:600px;
}
  */

/*30-07-2019*/
ul.profile-info {
  float: left;
  width: 100%;
  color: #ff6600;
  font-size: 20px;
  margin-bottom: 30px;
  font-weight: 600;
}

span.profile-tittle {
  float: left;
  width: 100%;
  color: #ff6600;
  font-size: 19px;
  margin-bottom: 30px;
  font-weight: 600;
  padding-left: 10px;
  border-left: 5px solid #ff6600;
  margin-top: 30px;
}

ul.profile-info {
  margin: auto;
  list-style: none;
  padding: 0;
}

li.detail-heading {
  color: #5f5e5f;
  font-size: 16px;
  margin-bottom: 40px;
  width: 50%;
  float: left;
}

li.detail-info {
  color: #a2a2a2;
  font-size: 16px;
  font-weight: normal;
  width: 50%;
  float: left;
}

.bg-w {
  border-bottom: 1px solid #eee;
  padding: 25px 0 25px 0;
}

.bg-g {
  background: #f7f7f7;
  border-bottom: 1px solid #eee;
  padding: 25px 0 25px 0;
}

.profile-card {
  -webkit-box-shadow: 0px 0px 3px -1px rgb(158, 148, 148);
  -moz-box-shadow: 0px 0px 3px -1px rgb(158, 148, 148);
  box-shadow: 0px 0px 3px -1px rgb(158, 148, 148);
  width: 100%;
  float: left;
  margin-bottom: 20px;
  width: 100%;
  background: #fff;
}

.profile-wraper {
  padding: 0;
  border-right: 1px solid #eee;
}

.border-l {
  border-left: 1px solid #eee;
}

.profile-img {
  text-align: center;
  padding-top: 60px;
}

  .profile-img > img {
    background: #c6c6c7;
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

.left-profile {
  margin-top: 25px;
  margin-left: 75px;
}

  .left-profile > ul.profile-info > li.detail-heading {
  }
/*table th, table td {
  padding: 5px !important; /* Apply cell padding
}*/

/*02-08-19   NG Recovery for all Employees TREE*/
.all-emp-tree > .mat-tree-node {
  display: table;
  align-items: center;
  min-height: 48px;
  flex: 1;
  /* overflow: hidden; */
  word-wrap: break-word;
}
/*7-8-19*/
/*.accordion-wrap {
  display: inline-block;
  width: 100%;
}*/
/*8-8-19*/
.tab-tr-bg {
  width: 100%;
}

  .tab-tr-bg th {
    background: #eee;
  }

.tab-th {
  padding: 10px 5px 10px 8px;
}

.table-title {
  float: left;
  text-align: center;
  width: 100%;
  background: #2f5484;
  padding: 5px;
  color: #fff;
  font-size: 16px;
  border-bottom: 1px solid #a7a7a7;
}

.mt-40 {
  margin-top: 40px;
}
/*02-09-2019*/

.margin-t-b-20px {
  margin: 20px 0;
}

.view-detail-btn {
  background: #c49663;
  color: #fff;
}

  .view-detail-btn:hover {
    color: #fff !important;
    background: #ad7d48;
  }

.view-btn-wraper {
  margin: 40px 0;
}

.close-icon {
  margin-right: 5px;
  float: right;
}

.note {
  background: #fff6cc;
  border: 1px solid #dfd9d9;
  padding: 7px;
  margin-bottom: 10px;
}

  .note > span {
    display: block;
    font-size: 20px;
  }


/*Start css for accordion on click 16-9-2019*/

.panel-header, .panel-header:active, .panel-header:hover, .panel-header:visited {
  color: #fff !important;
  background: #2468b3 !important;
  padding: 10px 20px 10px 10px !important;
  font-size: 14px;
  height: auto !important;
}

  .panel-header:active {
    background-color: #2468b3
  }

  .panel-header:hover {
    background-color: #2468b3 !important;
  }

  .panel-header:visited {
    background-color: #2468b3 !important;
  }

.mat-expansion-indicator::after {
  color: #fff;
}

.acordion-heading {
  color: #fff;
  font-weight: bold;
}

.btm-m15 {
  margin-bottom: 15px;
}

/*End css for accordion on click 16-9-2019*/
/*24-09-19*/
.select-radio {
  font-weight: 600;
}

.mt-10 {
  margin-top: 10px !important;
}

.pt-10 {
  padding-top: 10px;
}

/*30-9-2019*/
.multi-radio-btn > mat-radio-button {
  margin-right: 25px !important;
}

.radio-btn-lbl {
  font-weight: bold !important;
}

.padLeave {
  width: 100%
}
/*.padLeave th, td{
    padding: 5px 2px 5px 0px;
    padding-left:10px!important;
  }*/


/*16-10-19*/
.ml-0 {
  margin-left: 0px !important;
}

.div-center {
  width: 50%;
  margin: 0 auto;
  margin-top: 10%;
  padding-top: 15px;
}
/*18-10-19*/
td > input {
  border: 1px solid #d2cccc;
  padding-left: 3px;
}

.mt-20 {
  margin-top: 20px;
}

.row-height {
  height: 50px;
  text-align-last: center;
}

.btn-width {
  width: 150px;
}

.ckeditor-margin {
  margin-top: 5px;
  margin-bottom: 20px;
}

.ck-editor__editable_inline {
  min-height: 100px;
}

.select-width {
  width: 50%;
}

.mat-card-margin {
  margin-bottom: 10px;
  margin-top: 20px;
}

.team-background-color {
  background-color: beige;
}

.conference-background-color {
  background-color: #efefef;
}
.conference-background-color > p{
  color: #000;
}

table {
  border-collapse: collapse;
  width: 100%;
}

  table th {
    background-color: #686868 !important;
    color: #fff;
  }

table, td, th {
  border: 1px solid #ddd;
  text-align: left;
}

th, td {
  padding: 10px;
  /* background: #fff !important; */
}

.textbox-margin {
  margin-top: 10px;
}

.mat-header-cell {
  background-color: #686868 !important;
  display: flex;
  justify-content: center;
  border-right: 1px solid #ddd;
  color: #ffffff;
}

mat-cell {
  border-right: 1px solid #ddd;
  display: flex;
  justify-content: center;
}

.record-not-found {
  align-self: center;
}

a {
  cursor: pointer;
}
li{
    list-style:none;
}
.cal-month-view .cal-day-cell {
  min-height: 10px !important;
}

.cal-month-view .cal-cell-top {
  min-height: 10px !important;

}

/**Lastest CSS 04 Feb 2021**/
.btn-mail-intimation{
  min-width: unset; font-size:14px; padding:5px; margin-left: 12%;
}

@media screen and (min-width: 768px) and (max-width: 1024px){
  .btn-mail-intimation{min-width: unset; font-size:11px; padding:5px; margin-left: 12%;}
  .right-panel {margin-left:75px;}
}
@media screen and (min-width: 1280px) and (max-width: 1366px){
.mat-header-cell{
  /* padding: 20px 4px !important;
    font-size: 13px; */
}
.btn-mail-intimation{font-size:0.6rem;}
.pd-custom17{padding: 19px 22px 12px 25px !important;}
.btn {
  margin: 0 5px;
  min-width: 64px;
}
}
.wd45{
   width:50%;
   font-size: 13px;;
}
.wd25 {
  width: auto;
  font-size: 14px;
}
.mat-cell, .mat-footer-cell, mat-header-cell{
  word-break: break-all;justify-content: left;
}

.cross{position: absolute;
  top: 10px;
  right: 20px;}

.max-height-40vh{max-height:40vh;overflow-y:scroll;}

.bdr-right1{border-right:solid 1px #ddd;}
p.card-title{word-break:break-all;}
.min-height90vh{min-height: 82vh;background:#fff;}
.marquee-text{
  color: #6520ef;
  font-weight: 900;text-align:left;
  /* border: saddlebrown; */

  }

.forgot-pwd{padding:15px;border: solid 1px #303c54 !important; height: 100%; margin-top: 20px;}
  .forgot-pwd h3{text-align: center;
    background: #303c54;
    color: #fff;
    padding: 10px;
    border-radius: 5px;position:relative;top:-30px; font-size: 18px;}
    .forgot-pwd.user-details [class^='col-']{
      padding: 5px 0;
      border-top: 1px solid #c1bfbf;
    }
    .mat-table .mat-cell{padding:5px 10px; font-size: 13px;}
    .mat-header-cell{padding: 25px;}
    .table-border .mat-cell{padding:0px 24px;}
    mat-cell > mat-cell{border:none !important;}
   .RiskTab mat-row{align-items: unset !important;}
   .mh-50{min-height:50vh;max-height:50vh;}
   .min-height-20vh{min-height:20vh;}
   .welcomeText{font-size: 28px !important;
    padding: 35px 20px;
    color: #989898;
    font-weight: 600;
    position: absolute;
    top: 35%;
    margin: 0 auto;
    text-align: center;
    left: 30%;
    font-size: 75px !important;
    /* opacity: 0.4; */
    text-shadow: 10px 11px 11px #ffc1ec;
}
   /* .right-panel .breadcrumbs {position: relative;top:-10px;} *//*28jan*/
.pt-30{padding-top:30px;}
.pos77{position:relative;left:75%;}
/*28jan*/
.right-panel .breadcrumbs .breadcrumbs-inner,
.right-panel .breadcrumbs .breadcrumbs-inner .page-header,
.right-panel .breadcrumbs .breadcrumbs-inner .page-header .breadcrumbs {
    background-color: transparent;
  }
.right-panel .breadcrumbs .breadcrumbs-inner .page-header{
    min-height: inherit;
    /* padding-top: 5px; */
    /* padding-bottom: 5px; */
}
.right-panel .page-header .breadcrumb{background: transparent; padding:0;}
.cdk-overlay-pane{
  margin-top: 40px;
}
.mat-menu-panel.ng-trigger{margin-top: -40px;}

.right-panel .page-header h1{font-size: 22px; padding: 15px 0 0; color: #554576;}
/* .right-panel{background-image: linear-gradient(105deg,#f7a9c5,#ab9ec8) !important} */
.right-panel .page-header .breadcrumb{padding-top: 5px;font-size: 14px;}

.user-area .user-role{font-size: 13px; line-height: 1.2;}
.user-area .user-email{text-transform: lowercase;}
.card-header.pink{color: #393237;}
.card{border: 1px solid #eae7f0;}
.cell-email{width: 150px; overflow: hidden; overflow-wrap: initial; text-overflow: ellipsis;}
.mat-column-email{word-break: initial;  flex: 0 0 150px;}

.mat-cell, .mat-footer-cell, mat-header-cell{word-break: inherit; justify-content: center;}
.mat-table .mat-cell{text-align: center;}
mat-header-row, mat-row, mat-footer-row{min-height: 38px !important;}
mat-header-row .mat-sort-header-arrow{color: #FFF !important;}
mat-footer-row, mat-header-row, mat-row{align-items:unset !important;}

.mat-table .mat-header-row .mat-header-cell {padding: 0 25px;}

#calendar .fa-chevron-left{}
#calendar .fa-chevron-left, #calendar .fa-chevron-right {cursor: pointer;font-size: 14px;}
#calendar h5{margin-top: 5px; text-align: center}
#calendar .btn-group .btn{margin-right: 0 !important; margin-left: 10px; min-width: auto;}
.cal-month-view .cal-day-cell.cal-today .cal-day-number{background-color: #320b86;  color: #fff; text-align: center; width: 28px; height: 28px; border-radius: 50%; font-size: inherit; line-height: 28px;}
/* .mat-select-panel-wrap{margin-top: 49px;} */
.filter-right{display: flex !important; justify-content: flex-end;}
.filter-right .material-icons{color: #303c54;}
.filter-right .ng-star-inserted{text-transform: capitalize;}
.card-heading{font-size: 18px; color: #303c54; margin-bottom: 5px; }
#calendar{padding: 1.25em; border:1px solid #eae7f0; border-radius: 10px; box-shadow: 0 0 20px rgb(0 0 0 / 8%);}
.card #calendar h3{font-size: 16px !important; text-align: center;}
th,td, .mat-header-cell, .mat-cell{padding-top: 5px; padding-bottom: 5px; font-size: 14px; text-align: center;}
table th, .mat-header-cell{ background-color: #313c52 !important;}
.material-icons.btn.btn-error{padding: 0; min-width: inherit;}
.stat-widget-five{margin-bottom: 25px;}
.mat-column-Action a,.material-icons.i-edit,
.mat-column-sendLink .mat-icon, .material-icons.edit-icon{
        border: 1px solid #313C52;
    border-radius: 50%;
    font-size: 16px;
    color: #313C52 !important;
    margin-right: 10px;
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 22px;
    background-color: #FFF !important;
}
 .overlay{
      position: fixed;
    background: rgba(0,0,0,.5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1111;
}
.toggle-bar{
  z-index: 9999;
    cursor: pointer;
    font-size: 24px;
    color: #2b3748ae !important;
    position: fixed;
    top: 13px;
    left: 20px;
}
aside.left-panel{width: 240px; overflow: auto;}
.right-panel{margin-left: 240px; position: relative;}
.right-panel .navbar-header{padding: 0 1.25em 0 32px;}
aside.left-panel, .right-panel{ transition: all 0.3s ease-in-out;}
.wrapper.closeSidebar aside.left-panel{left: -240px;}
.wrapper.closeSidebar .right-panel{margin-left: 0px;}
.wrapper.closeSidebar aside.left-panel .menu-name{display: none;}
.dialog[_ngcontent-ng-cli-universal-c300]{
  top: 100px !important;
}
[ng-reflect-visible="true"]{position: relative;}
[ng-reflect-visible="true"]:after{
    content: '';
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 12;
}
.popup-modal .dialog{
  display: flex;
  justify-content: center;
  align-items: center;
}
.activate-user .dialog{
  max-width: 35% !important;
}
.popup-modal .dialog h3{font-size: 22px; margin-bottom: 30px;}
.welcomeText{color: #675f77; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 70%;}
.choosefile{text-align: right; margin-top: 10px;}
.choosefile .filename-box{
  display: flex;
  align-items: center;
}
.choosefile .filename-box a{
      display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 150px;
}
.filename-btn-box{
      float: left;
    width: calc(100% - 120px);
}
.fileUpload-block{
  display: flex;
  align-items: center;
}
.view-icon {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Free";
  border: 1px solid #673ab7;
    border-radius: 50%;
    font-size: 16px;
    color: #673ab7 !important;
    margin-right: 10px;
    width: 24px;
    height: 24px;
    line-height: 22px;
}
.view-icon:before{
content: "\f06e";
}

.mat-row-link {
    border-radius: 50%;
    font-size: 16px;
    color: #673ab7 !important;
    margin-right: 10px;
    width: 300px;
    height: 24px;
    line-height: 22px;
}
.mat-row-link:before{
content: "\f06e";
}



.i-delet{color: #ef4b4b !important;}
#flotBarChart{height: 472px !important;}
#calendar{height: 513px !important;}
/* .right-panel .page-header h1{font-weight: 700;} */
.enter-captcha .mat-form-field-wrapper{padding-bottom: 0 !important;}
.enter-captcha .mat-form-field-infix{padding-bottom: 12px !important;}


.navbar .navbar-nav ul.sub-menu li  a{padding: 4px 0 4px 30px !important; border-top: 1px solid rgba(255,255,255,0.1);}
.navbar .navbar-nav ul.sub-menu li a:hover, .navbar .navbar-nav ul.sub-menu li.active a{font-weight: inherit !important; background: #e55827 !important;}
.navbar .navbar-nav li > a.dropdown-toggle .fa-chevron-down{margin-left: 5px; vertical-align: middle;}
app-dialog .dialog, .mat-dialog-container{display: table; height: inherit;}
app-dialog .dialog .card-content, .mat-dialog-container .card-content{display: table-cell; vertical-align: middle;}
app-dialog .dialog .card-content .card-title, .mat-dialog-container .card-content .card-title{font-size: 20px; margin-bottom: 45px;}
.swal2-popup .swal2-title{font-size: 20px !important; font-weight: normal !important;}
app-dialog .dialog .card-content button{font-size: 14px;}
.mat-tab-body-content{overflow-x: hidden !important;}
.file-name__trim{
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 300px;
    vertical-align: middle;
    margin-bottom: 0;
}
.actions {
  display: flex;
  align-items: center;
  justify-content: center;
}
.action-items {
  width: 40px;
 }
.action-items .mat-icon-button{
  width: 24px;
  height: 24px;
  line-height: 1;
}
.mat-dialog-container{padding: 0 !important; border-radius: 12px !important; overflow-x: hidden !important; overflow-y: auto !important;}

.mat-icon-button.close-button{
  height: 35px !important;
  width: 35px !important;
}
.actions {
  display: flex;
  align-items: center;
  justify-content: center;
}
.action-items {
  width: 40px;
 }
.action-items .mat-icon-button{
  width: 24px;
  height: 24px;
  line-height: 1;
}
.padding25{padding: 25px !important;}
.align-modal{display: block !important; padding-top: 30px;}
.mat-icon-button.mat-primary{color: #313C52 !important;}
.ck.ck-list__item .ck-button.ck-on:hover:not(.ck-disabled), .ck.ck-list__item .ck-button.ck-on{background-color: #313C52 !important;}
.mat-calendar-table-header th{
  padding-top: 8px !important;
}
.mat-calendar-table-header-divider{
  display: none;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple, .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb{
  background-color: #303c54 !important;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
  border-color: #303c54 !important;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(49, 60, 82,.54) !important;
}
.mat-slide-toggle-thumb{
  background-color: #d7d7d7 !important;
}
.mat-slide-toggle-bar:hover .mat-slide-toggle-persistent-ripple{
  opacity: 0 !important;
}
footer{
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 123;
}
footer p{
  margin: 0;
  padding: 10px 15px;
  background: rgb(48,60,84);
background: linear-gradient(90deg, rgba(48,60,84,1) 0%, rgba(235,243,246,1) 60%);
font-weight: 600;
color: #FFF;
}

.right-panel .navbar-brand img {
  max-width: 206px !important;
}


.mat-dialog-container .card-header strong{
  font-weight: 600;
}
@media (min-width: 1460px){
  .container {
    max-width: 1440px;
}
}

@media (max-width: 1366px){

  .header-top{
    margin-bottom: 20px !important;
    font-size: 15px !important;
  }
  marquee{
    line-height: 1;
  }
  .login__form{
    margin-top: 20px !important;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .form-logo{
    height: 45px;
  }
  .person{
    margin-top: 20px !important;
  }
  .person img{
    height: 45px;
  }
  .cga-logo{
    height: 60px;
  }
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background{
  background-color: #303c54 !important;
}
.mat-header-row .mat-checkbox-frame, th .mat-checkbox-frame{
  border-color: #e55827 !important;
}
.mat-header-row .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-header-row .mat-checkbox-checked.mat-accent .mat-checkbox-background, th .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #e55827 !important;
}
  .extend-audit__calendar .cal-event-actions a{
    display: inline-block;
    text-align: center;
    vertical-align: middle;
  }
  .extend-audit__calendar .cal-draggable{
    padding: 5px 0;
  }
  .extend-audit__calendar .cal-event-actions a.i-delete{
    color: #ff1818;
  }
  .extend-audit__calendar .cal-month-view .cal-open-day-events{
    background-color: #024356;
  }
  .mat-column-sl-No .mat-sort-header-stem, .mat-column-sl-No .mat-sort-header-indicator, .mat-column-Sl-No .mat-sort-header-stem, .mat-column-Sl-No .mat-sort-header-indicator{
    display: none;
  }
.mat-datepicker-toggle{
  position: absolute;
  right: 0;
  bottom: 0;
}
.mat-datepicker-toggle svg{
  height: 16px;
}
label.date{
  margin-bottom: 0;
  font-size: 12px;
  position: absolute;
  top: -11px;
}
  .example-card{
  padding-top: 10px;
  padding-bottom: 10px;
}
.welcome-page div{
  background-image: url('assets/img/welcome-bg.jpg');
  background-size: cover;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.welcome-page p{
  position: relative;
    font-size: 40px;
    padding: 20px 0;
    text-align: center;
    margin-left: -90px;
}
.swal2-popup .swal2-styled.swal2-confirm{
  background-color: #303C54 !important;
  padding: 5px 15px !important;
}
.swal2-popup .swal2-actions{
  margin-top: 10px !important;
}
.timepicker__header, .clock-face__clock-hand, .clock-face__clock-hand:after, .clock-face__clock-hand_minute, .clock-face__clock-hand_minute:before, .clock-face__number > span.active{
  background-color: #303C54 !important;
}
.clock-face__clock-hand_minute:before{
  border-color: #303C54 !important;
}
.timepicker-button{
  color: #303C54 !important;
}
label.mat-checkbox-layout{
  margin-bottom: 0 !important;
}
.memo-observation{
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 10px !important;
}
.memo-observation strong, .memo-details strong{
  font-weight: 500;
}
.memo-observation label, .memo-details label{
  font-size: 12px;
}
.memo-observation a, .memo-details a{
  font-size: 20px !important;
  display: inline-block;
  vertical-align: middle;
  color: #fc2e2e !important;
}
.memo-observation a:hover, .memo-details a:hover{
  text-decoration: none !important;
}
.memo-observation a.material-icons, .memo-details a.material-icons{
  font-size: 16px;
  margin-left: 5px;
}
.observation-time{padding-top: 10px;}
.memo-details p{
  margin-bottom: 0 !important;
}

.mat-sort-header-container.mat-focus-indicator{
  outline: none;
  box-shadow: none;
  text-decoration: none;
}
.mT-30{
  margin-top: 30px !important;
}
.mB-30{
  margin-bottom: 30px !important;
}
.custom_overlay{
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0,0,0,0.5);
}
